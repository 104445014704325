.top-panel {
  width: 100%;
  height: 48px;
  display: grid;
  grid-template-columns: 1fr 230px 1fr;
  font-family: $builder-font-bold;
  align-items: center;
  justify-items: center;
  border-bottom: 1px solid $builder-grey-30;
  background-color: var(--elo-primary-wg-100);
  padding: 0 15px;

  &__section {
    display: flex;
    align-items: center;

    &--left {
      display: flex;
      justify-content: flex-start;
      justify-self: flex-start;
    }
    &--right {
      display: flex;
      justify-content: flex-end;
      justify-self: flex-end;
    }
  }

  // * + &__control {
  //   margin-left: 20px;

  // }

  &__control {
    font-size: $builder-font-size-md;
    color: $elo-grey-50;
    vertical-align: middle;
    cursor: pointer;

    &:hover {
      color: $elo-grey-70;
    }

    &.active {
      color: $elo-grey-90;
    }
  }

  &__title {
    font-size: 13px;
    color: $elo-black;
    margin-left: 18px;
    font-weight: 400;
    white-space: nowrap;
  }

  &__live-template-tooltip span {
    position: relative;
    top: -7px;
    font-family: $builder-font-medium;
    font-size: 10px;
    color: $elo-white;
    padding: 5px 9px 4px 10px;
    border-radius: 9px;
    background-color: $elo-orange;
    cursor: pointer;
    margin-left: 3px;

    &:hover {
      background-color: $elo-orange-90;
    }
  }

  &__view-switcher {
    font-size: 14px;
    min-width: 20px;
    text-align: center;

    svg {
      margin-right: -8px;
    }
  }

  &__buttons {
    min-width: 420px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }

  &__saving_indicator {
    display: inline-block;
    margin-left: 8px;
    font-size: 12px;
    color: $elo-grey-70;
    font-family: Inter;
    min-width: 110px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
