@import '../../../styles/colors';
@import '../../../styles/fonts';

$light-blue: #d2e6f7;

.popover {
  box-shadow: none;
  border: 0;

  .popover-body {
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    font-size: 12px;
    color: $elo-grey;
    padding: 15px;
  }

  .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
  }

  .arrow::before,
  .arrow::after {
    position: absolute;
    display: block;
    content: '';
    border-color: transparent;
    border-style: solid;
  }
}

.popover-info {
  margin-left: 6px;
  outline: none;
}

svg:hover,
svg:active {
  .popover-info.popover-info-circle {
    fill: $elo-grey;
  }
}

.tooltip {
  .tooltip-inner {
    box-shadow: 0 2px 15px rgba(var(--elo-black--rgb), 0.2);
    background-color: var(--tooltip-bg-color);
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    font-size: 12px;
    color: var(--tooltip-font-color);
    padding: 15px;
  }

  a {
    color: var(--tooltip-font-color);

    &:active,
    &:hover {
      color: var(--tooltip-font-color);

      text-decoration: none;
    }
  }

  &.bs-tooltip-left .arrow::before {
    border-left-color: var(--tooltip-bg-color) !important;
  }

  &.bs-tooltip-right .arrow::before {
    border-right-color: var(--tooltip-bg-color) !important;
  }

  &.bs-tooltip-top .arrow::before {
    border-top-color: var(--tooltip-bg-color) !important;
  }

  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: var(--tooltip-bg-color) !important;
  }
}

.collapse-block {
  .cb-header {
    border-radius: 4px;
    background-color: $elo-grey-light-40;
    padding: 15px 25px;

    .checkbox-switch {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        align-items: center;
        display: flex;
      }

      .switcher-span {
        margin-right: 0;
      }

      label {
        margin-bottom: 0;
      }

      span {
        font-family: var(--elo-font-sans-serif);
        font-weight: 400;
        font-size: var(--elo-font-size-sm);
        text-align: left;
        color: $elo-grey-dark;
      }

      .switcher-input:checked ~ label {
        background: $elo-green;
      }
    }
  }

  .cb-body {
    border-radius: 0 0 4px 4px;
    background-color: $elo-grey-light-10;
    border: solid 1px $elo-grey-light;
    padding: 20px 25px 30px;
  }

  &.disabled {
    .cb-header {
      opacity: 0.6;
    }
  }

  &.active {
    .cb-header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.book-option-init {
  display: inline-block;
  background: $elo-white;
  border: 1px solid $elo-grey-40;
  padding: 8px 12px;
}

.book-option-wrap {
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
  font-family: var(--elo-font-sans-serif);
  font-weight: 400;
  color: $elo-black-semi;

  .book-option-img {
    height: 84px;
    width: 84px;
    border-radius: 6px;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: inline-block;
  }

  .book-option-name {
    font-size: 16px;
    font-weight: 500;
  }

  .book-option-descr {
    font-size: 12px;
    color: rgba($elo-black, 0.56);
  }

  .book-option-actions {
    margin-top: 10px;

    .bo-free,
    .bo-price {
      margin-top: 10px;
      font-size: 10px;
      font-weight: 500;
      color: $elo-grey;
    }
  }
}

.checkbox-switch {
  display: flex;
  align-items: baseline;

  .centered {
    display: inherit;
    align-items: end;
  }
}

.tooltip-menu {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;

  & + .tooltip-menu-close {
    z-index: 1;
  }

  &__container {
    display: flex;
    justify-content: flex-end;

    &--right {
      padding-right: 15px;
      justify-content: flex-end !important;
    }
    &--center {
      padding-right: 0;
      justify-content: flex-start !important;
      margin: auto;
      padding-left: 6px;
    }
  }

  .elo-btn-icon {
    margin: 0;
  }

  &:focus {
    outline: none;
  }

  &__column {
    position: relative;
    width: 60px;

    .tooltip-menu-close {
      z-index: 1;
    }
  }

  &__icon {
    color: var(--action-button-font-color);
    font-size: 22px;
    cursor: pointer;

    &:hover {
      color: var(--action-button-font-color-hover);
    }
  }

  &__list-container {
    padding: 0;
  }

  &__item {
    background: none;
    border: unset;
    color: var(--dropdown-font-color);
    cursor: pointer;
    height: 28px;
    text-align: left;
    line-height: 2;
    padding: 0 27px;
    font: {
      family: var(--elo-font-sans-serif);
      size: 12px;
      style: normal;
      weight: 500;
    }

    &--pdf {
      padding: 8px 24px;
    }

    &--info {
      padding: 0 24px;
    }

    &--parent {
      background-color: var(--elo-light-grey-70);
    }

    &--subitem {
      background-color: var(--elo-light-grey-30);
    }

    &.action-icon {
      font-size: var(--elo-font-size-md);
      padding: 0 16px;

      &:hover {
        background: none;
        color: $elo-blue;
      }
    }

    &:hover {
      background-color: var(--dropdown-item-bg-color-hover);
    }

    &:focus {
      outline: none;
    }

    &--edit {
      position: relative;

      .tooltip-menu__hover-menu-container {
        display: none;
      }

      &:hover {
        .tooltip-menu__hover-menu-container {
          display: flex;
        }
      }
    }

    &--grey {
      background-color: var(--elo-light-grey-60);
      width: 100%;
    }
  }

  &__popover {
    box-shadow: none;
    border: 0;
    max-width: initial;
    display: flex;
    border-radius: 5px;
    z-index: 1060;
    background-color: var(--dropdown-bg-color);

    .popover-body {
      display: flex;
      flex-direction: column;
      padding: 12px 0;
    }

    &.actions {
      .popover-body {
        flex-direction: row;
      }
    }

    ul {
      margin: 0;
      list-style-type: none;
      font-style: normal;
    }

    &--with-icons {
      .popover-body {
        flex-direction: row;
        padding: 12px 10px;
      }

      .tooltip-menu__item {
        height: 100%;
        padding: 0 3px;
        line-height: 1;

        &:hover {
          background: none;
        }

        .elo-btn-icon {
          font-size: var(--elo-font-size-md);
        }

        .elo-btn-icon--disabled {
          color: $elo-grey;
          font-size: 16px;
          cursor: default;
        }
      }
    }
  }

  &__popover-container {
    border: 0;
    box-shadow: none;
  }

  &__hover-menu-container {
    flex-direction: column;
    position: absolute;
    top: -12px;
    left: -145px;
    width: 145px;
    padding: 12px 0;
    border-radius: 6px;
    background-color: var(--dropdown-bg-color);

    a {
      display: flex;
      align-items: center;
      cursor: pointer;
      background: none;
      border: unset;
      text-align: left;
      font-size: 12px;
      line-height: 2;
      color: var(--dropdown-font-color);
      font-family: var(--elo-font-sans-serif);
      font-weight: 500;
      padding: 0 20px;
      height: 28px;
      text-decoration: none;

      &:hover {
        color: var(--dropdown-font-color);
        background-color: var(--dropdown-item-bg-color-hover);
      }
    }
  }
}

.tooltip-menu-close {
  position: fixed;
  top: 0;
  left: 0;
  // for some reason z-index was set 0
  z-index: 0;
  width: 100%;
  height: 100%;
}

.bs-popover-auto[x-placement^='top'] > .arrow::before {
  border-top-color: var(--dropdown-bg-color) !important;
  margin-bottom: 1px;
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-auto[x-placement^='top'] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: var(--dropdown-bg-color) !important;
}

.bs-popover-auto[x-placement^='right'] > .arrow::before {
  border-right-color: var(--dropdown-bg-color) !important;
  margin-left: 1px;
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-auto[x-placement^='right'] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: var(--dropdown-bg-color) !important;
}

.bs-popover-auto[x-placement^='bottom'] > .arrow::before {
  border-bottom-color: var(--dropdown-bg-color) !important;
  margin-top: 1px;
  top: 0;
  border-width: 0 0.5rem 0.5rem;
}

.bs-popover-auto[x-placement^='bottom'] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: var(--dropdown-bg-color) !important;
}

.bs-popover-auto[x-placement^='left'] > .arrow::before {
  border-left-color: var(--dropdown-bg-color) !important;
  margin-right: 1px;
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-auto[x-placement^='left'] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: var(--dropdown-bg-color) !important;
}

.bs-popover-auto[x-placement^='top'] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^='right'] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^='bottom'] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^='left'] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^='top'] {
  margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^='right'] {
  margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^='bottom'] {
  margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^='left'] {
  margin-right: 0.5rem;
}
