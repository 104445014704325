.custom-tooltip {
  font-style: normal;

  .fa-file-pdf,
  .fa-image,
  .fa-envelope {
    font-size: 16px;
    color: var(--icon-button-color);
    cursor: pointer;

    &:hover {
      color: var(--icon-button-color-hover);
    }
  }

  &__title {
    font-family: var(--elo-font-sans-serif);
  }

  svg:hover,
  svg:active {
    .popover-info-icon-circle {
      fill: var(--elo-grey);
    }
  }
}

.popover-info-icon {
  cursor: pointer;
  opacity: 1;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right'] {
  padding: 0 0.4rem;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left'] {
  padding: 0 0.4rem;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top'] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom'] {
  padding: 0.4rem 0;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow {
  bottom: 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow {
  top: 0;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.tooltip.bs-tooltip-top .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: var(--tooltip-bg-color) !important;
}

.tooltip.bs-tooltip-bottom .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: var(--tooltip-bg-color) !important;
}

.tooltip.bs-tooltip-right .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: var(--tooltip-bg-color) !important;
}

.tooltip.bs-tooltip-left .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: var(--tooltip-bg-color) !important;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}
