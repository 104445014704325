@import 'src/ui-kit/styles/colors';
@import 'src/ui-kit/styles/fonts';

.warning-message {
  padding: 15px;
  border: 1px solid $elo-orange;
  border-radius: 4px;
  margin-bottom: 20px;

  .fa-exclamation-triangle {
    font-size: var(--elo-font-size-md);
    color: $elo-orange;
    margin-bottom: 7px;
    margin-right: 5px;
  }

  &__title {
    color: $elo-black-semi;
    font-size: var(--elo-font-size-sm);
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;
  }

  &__message {
    font-size: var(--elo-font-size-sm);
    line-height: 18px;
    color: $elo-grey-dark;
    font-style: normal;
  }
}
