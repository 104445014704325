#klarna-product-message-element::part(osm-container) {
  border-color: firebrick;
  border-style: dashed;
  border-width: 1px;
  background-color: #453c67;
}

#my-credit-promotion::part(osm-message),
#my-credit-promotion::part(osm-cta) {
  color: #46c2cb;
}

#my-credit-promotion::part(osm-badge) {
  width: 70px;
}
