.side-panel__edit {
  &__header {
    display: flex;
    justify-content: space-between;
    color: $elo-grey-90;
    margin-bottom: 5px;
  }

  &__title {
    font-size: $builder-font-size-md;
    font-family: $builder-font-bold;
    max-width: 215px;
    text-overflow: ellipsis;
    overflow: auto;

    i {
      margin-right: 8px;
    }
  }

  &__close-btn {
    font-size: 16px;
    cursor: pointer;
    margin-left: 15px;
    color: $elo-grey-50;
  }
}
