@import 'src/ui-kit/styles/colors';
@import 'src/ui-kit/styles/fonts';

.country-label {
  font-family: var(--elo-font-sans-serif);
  font-weight: 500;
  background-color: $elo-grey-40;
  color: $elo-white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 5px;
  padding: 6px 3px 5px;
}
