.elo-input {
  --elo-input-font: var(--elo-body-sm-regular);
  --elo-input-color: var(--color-primary-dg-brand);
  --elo-input-background-color: var(--color-primary-neutral-white);
  --elo-input-border-color: var(--color-primary-dg-200);

  --elo-input-placeholder-color: var(--color-primary-dg-300);

  --elo-input-label-font: var(--elo-body-sm-medium);
  --elo-input-label-color: var(--color-primary-dg-brand);

  --elo-input-success-color: var(--color-utility-success-dark);
  --elo-input-error-color: var(--color-utility-error-dark);
  --elo-input-hint-color: var(--color-primary-dg-300);
  --elo-input-counter-color: var(--color-primary-dg-300);

  --elo-input-icon-color: var(--color-primary-dg-brand);

  --elo-input-with-tags-color: var(--color-primary-dg-300);
  --elo-input-with-tags-focus-color: var(--color-highlight-click);
  --elo-input-with-tags-disabled-color: var(--color-primary-dg-100);
  --elo-input-with-tags-border-color: var(--color-primary-dg-200);
  --elo-input-with-tags-option-background-color: var(--color-primary-dg-100);
  --elo-input-with-tags-option-focus-background-color: var(--color-primary-cg-brand);
  --elo-input-with-tags-option-active-background-color: var(--color-primary-dg-200);

  display: flex;
  flex-direction: column;
  font: var(--elo-input-font);

  &,
  * {
    box-sizing: border-box;
  }

  > * + * {
    margin-top: 4px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &__label {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  &__label-text {
    position: relative;
    margin-bottom: 4px;
    font: var(--elo-input-label-font);
    color: #000004;
  }

  &__icon {
    position: absolute;
    right: 16px;
    bottom: 16px;
    pointer-events: none;
    line-height: 0;
    z-index: 1;
    color: var(--elo-input-icon-color);
    background-color: var(--elo-white);
  }

  &__success {
    color: var(--elo-input-success-color);
  }

  &__error {
    color: var(--elo-input-error-color);
  }

  &__hint {
    color: var(--elo-input-hint-color);
  }

  &__counter {
    color: var(--elo-input-counter-color);
  }

  &__label-required {
    color: var(--color-utility-error-mid);
  }

  &__label-description {
    font: var(--elo-input-font);
  }

  &__label-tooltip {
    margin-left: 4px;
    position: absolute;
    bottom: -3px;
    color: var(--color-primary-dg-400);
  }

  &--error {
    --elo-input-icon-color: var(--color-utility-error-dark);

    textarea,
    textarea:focus,
    input,
    input:focus {
      --elo-input-border-color: var(--elo-input-error-color);
    }

    input {
      padding-right: 42px;
    }
  }

  &--success {
    --elo-input-icon-color: var(--color-utility-success-dark);

    textarea,
    textarea:focus,
    input,
    input:focus {
      --elo-input-border-color: var(--elo-input-success-color);
    }

    input {
      padding-right: 42px;
    }
  }

  &--medium {
    input {
      padding: 10px 16px;
    }

    .elo-input__icon {
      right: 10px;
      bottom: 9px;
    }
  }

  &__label {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  &.disabled {
    textarea,
    input {
      --elo-input-color: var(--color-primary-dg-300);
      --elo-input-background-color: var(--color-primary-dg-100);
      --elo-input-border-color: var(--color-primary-dg-200);
      cursor: not-allowed;
    }
  }

  textarea,
  input {
    width: 100%;
    font: var(--elo-input-font);
    color: var(--elo-input-color);
    background-color: var(--elo-input-background-color);
    border: 1px solid var(--elo-input-border-color);

    border-radius: 4px;
    outline: none;
    text-decoration: none;

    ::-webkit-input-placeholder {
      color: var(--elo-input-placeholder-color);
    }

    ::-moz-placeholder {
      color: var(--elo-input-placeholder-color);
    }

    :-ms-input-placeholder {
      color: var(--elo-input-placeholder-color);
    }

    :-moz-placeholder {
      color: var(--elo-input-placeholder-color);
    }

    &:focus {
      --elo-input-border-color: var(--color-highlight-click);
    }
  }

  input {
    line-height: 12px;
    padding: 16px;
    height: 48px;
  }

  textarea {
    padding: 16px;
    line-height: 14px;
  }

  textarea[resize='none'] {
    resize: none;
  }

  textarea[resize='vertical'] {
    resize: vertical;
  }

  textarea[resize='horizontal'] {
    resize: horizontal;
  }

  textarea[resize='both'] {
    resize: both;
  }
}

// Password input type styles
.elo-input--password,
.elo-input--search {
  .elo-input__icon {
    cursor: pointer;
    pointer-events: visible;

    --elo-input-icon-color: var(--color-primary-dg-brand);
  }
}

// Number input type styles
.elo-input--number {
  input {
    appearance: none;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .elo-input__number {
    position: relative;
    line-height: 14px;
    vertical-align: text-top;
  }

  .elo-input__number-button {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: visible;
    line-height: 0;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    padding: 0;
    font: var(--elo-input-font);
    color: var(--elo-input-color);
    line-height: 0;

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      color: var(--color-primary-dg-300);
    }

    &:first-of-type {
      left: 16px;
    }

    &:last-of-type {
      right: 16px;
    }

    &:disabled {
      --elo-input-color: var(--color-primary-dg-300);
      cursor: not-allowed;
    }
  }
}

// Link input type styles
.elo-input--link {
  .elo-input__text {
    display: flex;
  }

  .elo-input__prefix {
    font: var(--elo-input-font);
    color: var(--color-primary-dg-300);
    background-color: var(--color-primary-dg-100);
    border: 1px solid var(--elo-input-border-color);
    padding: 16px 16px;
    line-height: 12px;
    border-right-width: 0;
    border-radius: 4px 0 0 4px;
  }

  input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

// With sign input type styles
.elo-input--with-sign {
  &__left,
  &__right {
    position: absolute;
    left: 16px;
    bottom: 17px;
    font: var(--elo-input-font);
    color: var(--color-primary-dg-300);
  }

  &__right {
    right: 16px;
    left: auto;
  }

  input {
    padding-left: 34px;
  }

  .right {
    padding-left: 1.6rem;
  }
}

// Copy input type styles
.elo-input--copy {
  .elo-input__icon {
    cursor: pointer;
    pointer-events: visible;
  }

  &.disabled .elo-input__icon {
    --elo-input-icon-color: var(--color-primary-dg-300);
    cursor: not-allowed;
  }

  &.elo-input--success,
  &.elo-input--error {
    --elo-input-icon-color: var(--color-primary-dg-brand);
  }

  input {
    padding-right: 42px;
  }
}

// Search input type styles
.elo-input--search {
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  input {
    padding-right: 30px !important;
  }
}

.elo-input--phone {
  &.disabled {
    .elo-input--phone__input {
      color: var(--color-primary-dg-300);
    }
    .arrow {
      opacity: 0.4;
    }
  }
  &__input {
    padding: 13px 16px 11px 85px !important;
    border-radius: 0 4px 4px 0;
    line-height: 22px !important;
    height: auto !important;
    margin-left: 16px;

    background-color: var(--elo-input-background-color);
    border: 1px solid var(--elo-input-border-color);
  }
  .react-tel-input {
    .flag-dropdown {
      border: 1px solid var(--elo-input-border-color);
      background-color: var(--color-primary-neutral-white) !important;
    }
  }

  .elo-input--phone__input:focus + .flag-dropdown {
    border: 1px solid var(--color-highlight-click);
  }

  .react-tel-input .form-control {
    border: 1px solid var(--elo-input-border-color);
  }

  .react-tel-input .elo-input--phone__input {
    &--full-width {
      width: 100%;
    }
  }

  .react-tel-input .form-control,
  .selected-flag {
    background: var(--color-primary-neutral-white) !important;
  }

  .elo-input--phone__button {
    .selected-flag {
      margin-left: 16px !important;
      background-color: var(--color-primary-neutral-white);

      .arrow {
        border: none;
        background-image: url('data:image/svg+xml,<svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.2 1L8.19995 8L1.19995 1" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 50%;
        position: relative;
        left: 40px;
        top: 7px;

        &.up {
          border: none;
          transform: rotate(180deg);
        }
      }
    }
  }

  &__drop-down {
    border-radius: 8px;

    .country {
      display: flex;
      align-items: baseline;
      font: var(--elo-body-sm-regular);
      padding: 16px 24px !important;
    }
  }

  &__button {
    background-color: var(--elo-input-background-color) !important;
    border-right: none !important;
    border-radius: 4px 0 0 4px !important;
  }

  &__disabled {
    background-color: var(--elo-primary-40) !important;
    pointer-events: none !important;
  }

  input[disabled] {
    background-color: var(--elo-primary-40);
    color: var(--elo-primary-100);
  }

  &.elo-input--medium {
    .elo-input--phone__input {
      padding: 9px 16px 7px 85px !important;
    }

    .selected-flag .flag {
      top: 17px !important;
    }
  }
}

// With tags input type styles
.elo-input {
  .elo-input-with-tags {
    &__option {
      background-color: var(--elo-input-with-tags-option-background-color);
      border-radius: 8px;

      &--is-focused {
        background-color: var(--elo-input-with-tags-option-focus-background-color);
      }

      &:active {
        background-color: var(--elo-input-with-tags-option-active-background-color);
      }
    }

    &__value-container {
      gap: 8px;
      padding: 16px;
    }

    &__menu {
      margin-top: 0;
      box-shadow: var(--shadow-elevation-3);
      border-radius: 8px;
    }

    &__menu:has(*) {
      box-shadow: var(--shadow-elevation-3);
    }

    &__menu-list {
      padding: 16px 8px;
    }

    &__control {
      display: block;
      min-height: 160px;
      color: var(--elo-input-with-tags-color);
      border: 1px solid var(--elo-input-with-tags-border-color);

      &--is-focused {
        border: 1px solid var(--elo-input-with-tags-focus-color);
        box-shadow: none;

        &:hover {
          border-color: var(--elo-input-with-tags-focus-color);
        }
      }

      &--is-disabled {
        background-color: var(--elo-input-with-tags-disabled-color);
      }
    }

    &__placeholder {
      color: var(--elo-input-placeholder-color);
    }
  }

  &.elo-input-with-tags--with-actions {
    .elo-input-with-tags__control {
      display: flex;
      align-items: flex-start;
    }

    .elo-input-with-tags__indicators {
      align-self: flex-start;
    }
  }

  &--error {
    .react-tel-input .form-control,
    .react-tel-input .flag-dropdown,
    .elo-input-with-tags__control {
      border-color: var(--elo-input-error-color);

      &:hover {
        border-color: var(--elo-input-error-color);
      }
    }
  }

  &--success {
    .react-tel-input .form-control,
    .react-tel-input .flag-dropdown,
    .elo-input-with-tags__control {
      border-color: var(--elo-input-with-tags-focus-color);

      &:hover {
        border-color: var(--elo-input-with-tags-focus-color);
      }
    }
  }

  &.elo-input-with-tags.disabled {
    .elo-tag__icon {
      display: none;
    }
  }
}

.elo-input--large {
  input {
    padding: 10px 16px;
    line-height: 18px;
    height: 40px;
  }

  .elo-input__icon {
    right: 10px;
    bottom: 11px;
  }
}
