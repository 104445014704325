@import '../../../styles/colors';

.confirmation-modal {
  &__title {
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 1.2;

    i {
      margin: auto 10px auto 0;
      color: $elo-orange;
      font-size: 30px;
    }

    div {
      margin-top: 5px;
    }

    .fa-envelope,
    .fa-paper-plane,
    .fa-info-circle,
    .fa-check-circle {
      color: $elo-green;
    }
  }

  .modal-content {
    .modal-body {
      padding-top: 10px;
      padding-bottom: 50px;
    }
  }

  &__message {
    margin: 0 50px;
    color: var(--modal-description-font-color);
    font-size: 13px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
