.error-info {
  background-color: var(--error-info-bg-color);
  border: solid 1px var(--error-info-border-color);
  border-radius: 4px;
  color: var(--info-font-color);
  display: flex;
  padding: 14px 12px;
  width: 100%;

  & + .file-list-wrap {
    margin-top: 10px;
  }

  i {
    color: var(--error-info-icon-color);
    font-size: var(--elo-font-size-md);
    margin-right: 10px;
  }

  a {
    color: var(--link-font-color);
  }
}
