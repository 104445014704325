@import '../../../styles/colors';
@import '../../../styles/fonts';

.old-price {
  font-family: var(--elo-font-sans-serif);
  font-weight: 400;
  color: var(--elo-red);
  font-size: 13px;
  margin-top: 5px;
  position: relative;

  &::before {
    content: '';
    height: 1px;
    position: absolute;
    background: var(--elo-red);
    width: 100%;
    left: 0;
    top: 7px;
  }

  &--windows::before {
    top: 7px;
  }
}
