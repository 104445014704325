@import 'src/ui-kit/styles/colors';

.info-action-bar {
  width: 100%;
  background-color: $elo-red;
  display: flex;
  align-items: center;
  padding: 15px;
  color: $elo-white;
  border-radius: 4px;

  &.orange {
    background-color: $elo-orange;
  }

  i {
    color: $elo-white;
    font-size: 20px;
  }

  div {
    flex: 2;
    font-size: var(--elo-font-size-sm);
    margin: 2px 16px;
    overflow-wrap: break-word;
  }

  .elo-btn {
    justify-self: end;
  }

  a {
    color: $elo-white;
    text-decoration: underline;
  }
}
