@import '../../../styles/colors';

.number-field {
  &__control {
    box-sizing: border-box;
    padding: 14px 30px 14px 18px;
    line-height: 44px;
    height: 44px;
    border: 1px solid var(--field-border-color);
    border-radius: 4px;
    background-color: var(--field-bg-color);

    //* Safari specific selector */
    @media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
      line-height: 30px;
    }

    &:focus:not(&--error) {
      border: 1px solid var(--field-border-color-focus);
    }

    &--error {
      border: 1px solid var(--field-border-color-error);
    }

    &--with-currency {
      border-radius: 0 4px 4px 0;
    }

    // Override Bootstrap styles
    &::placeholder {
      color: var(--field-placeholder-font-color) !important;
      font-size: 14px !important;
      line-height: 1.4 !important;
    }

    &--float {
      padding-right: 18px;
    }
  }

  &--with-static-text {
    min-width: 150px;
  }

  .field__error {
    min-height: 15px;
    height: auto;
  }

  &__currency {
    border: 1px solid $elo-grey-40;
    border-radius: 4px 0 0 4px;
    min-width: 40px;
    color: $elo-grey;
    font-family: var(--elo-font-sans-serif);
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--elo-light-grey-10);
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  &__control-wrapper {
    position: relative;
    width: 100%;
    display: flex;
  }

  &__static-text {
    color: $elo-grey-dark;
    top: 17px;
    position: absolute;
    right: 40px;
    line-height: 1;

    &--one {
      left: 30px;
    }

    &--two {
      left: 40px;
    }

    &--three {
      left: 50px;
    }

    &--four {
      left: 60px;
    }

    &--five {
      left: 70px;
    }
  }

  &__control-arrows {
    position: absolute;
    width: 45px;
    height: 100%;
    top: 0;
    right: 0;

    &--up,
    &--down {
      width: 0;
      height: 0;
      margin: 0 auto;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }

    &--up {
      border-bottom: 5px solid $elo-grey;
      margin: 15px auto 4px;

      &:hover {
        border-bottom-color: $elo-grey-dark;
        cursor: pointer;
      }
    }

    &--down {
      border-top: 5px solid $elo-grey;

      &:hover {
        border-top-color: $elo-grey-dark;
        cursor: pointer;
      }
    }

    &--disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  &--disabled {
    background-color: var(--field-bg-color-disabled);
    pointer-events: none;

    .popover-info {
      pointer-events: auto;
      cursor: pointer;
    }
  }

  &--required {
    .field__label {
      span:first-child::after {
        content: '*';
        margin: -1px 2px;
        font-size: 15px;
        color: rgb(255, 0, 0);
      }
    }
  }
}

@for $i from 1 through 5 {
  .number-field__static-text--#{$i} {
    left: calc(20px + #{$i} * 10px);
  }
}
