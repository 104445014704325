@import '../../styles/colors';
@import '../../styles/fonts';

.boolean-label {
  padding: 4px 4px 3px;
  line-height: 1;
  font-family: var(--elo-font-sans-serif);
  font-weight: 500;
  font-size: var(--elo-font-size-xs);
  text-transform: capitalize;
  text-align: center;
  color: $elo-white;
  background-color: $elo-grey-dark;
  border-radius: 3px;

  &--active {
    background-color: $elo-green;
  }

  &--just-text {
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;
    font-size: var(--elo-font-size-sm);
    padding: 0;
    background-color: unset;
    color: $elo-grey;

    &--active {
      color: $elo-green;
    }
  }
}
