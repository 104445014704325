.paypal-controls {
  &__buttons-container {
    position: relative;
    z-index: 1;

    &--preview {
      .paypal-buttons-context-iframe {
        height: 120px !important;
      }
    }

    @media (min-width: 300px) {
      &--preview {
        .paypal-buttons-context-iframe {
          height: 170px !important;
        }
      }
    }
  }

  &__message-container {
    &--preview {
      iframe {
        height: 130px !important;
      }
    }

    @media (min-width: 300px) {
      &--preview {
        iframe {
          height: 100px !important;
        }
      }
    }
  }
}
