.side-panel__add-item {
  $this: &;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: $elo-grey-70;
  min-height: 34px;
  border: 1px dashed $builder-grey-30;
  border-radius: 2px;
  margin-bottom: 5px;
  padding: 5px 10px;
  cursor: grab;

  &:hover {
    box-shadow: 0 3px 8px 0 #00000033;

    #{$this}__btn {
      display: block;
      color: $elo-grey-50;
    }
  }

  #{$this}__description {
    display: flex;
    align-items: center;

    i {
      margin-right: 7px;
    }
  }

  #{$this}__title {
    font-size: 12px;
    font-family: $builder-font-bold;
    text-transform: uppercase;
    margin-top: 2px;
  }

  #{$this}__btn {
    margin-left: 10px;
    display: none;
  }
}
