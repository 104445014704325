@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/elo-field';

.product-access {
  padding: 50px 40px;
  border-radius: 4px;
  background-color: $elo-white;
  box-shadow: 0 5px 15px 0 rgba($elo-black, 0.1);
  margin: 60px 0 80px;
  text-align: center;

  &__container {
    max-width: 100%;
    min-width: 35%;
    width: 590px;
    margin: 0 auto;

    @media (max-width: 576px) {
      max-width: 100%;
      width: 90%;
    }
  }

  &__header {
    margin-bottom: 20px;
    text-align: center;
  }

  &__title {
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;
    font-size: 24px;
    color: $elo-black-semi;
    margin-bottom: 5px;
    line-height: 1;
  }

  &__description {
    font-size: 15px;
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    color: $elo-grey;
    line-height: 16px;
    margin-bottom: 40px;
  }

  &__btn {
    padding: 16.5px;
    font-size: 15px;
    border-radius: 30px;
    line-height: 1;
    height: 50px;
    width: 60%;
    margin-top: 10px;
  }

  &__field {
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    color: var(--field-font-color);
    box-sizing: border-box;
    line-height: 44px;
    border: 1px solid var(--field-border-color);
    border-radius: 4px;
    background-color: var(--field-bg-color);
    width: 100%;
    font-size: 15px;
    height: 50px;
    padding: 15px 20px 12px;

    &::placeholder {
      font-size: 15px !important;
      color: var(--field-placeholder-font-color) !important;
    }

    &--error {
      border: 1px solid var(--field-border-color-error);
    }
  }

  .field__error {
    display: flex;
    justify-content: flex-end;
  }
}
