@import '../../styles/colors';
@import '../../styles/fonts';

.file-details {
  display: flex;
  justify-content: space-between;
  width: 95%;
  background-color: var(--dark-widget-bg-color);
  color: var(--dark-widget-font-color);

  &--progress {
    width: 100%;
  }

  &--long {
    width: 100%;
  }

  .file-item-preview {
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: var(--image-cover-bg-color);
    margin-right: 13px;

    i {
      font-size: var(--elo-font-size-md);
      color: var(--image-cover-font-color);
    }
  }

  &--border {
    padding: 6px;
    border-radius: 4px;
  }

  &__content-container {
    display: flex;
    width: 60%;
    align-items: center;

    .cover__img {
      margin-right: 13px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 80%;
  }

  &__text {
    display: flex;
    align-items: center;
  }

  &__name {
    // color: $elo-black-semi;
    font-size: var(--elo-font-size-sm);
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    a {
      // color: $elo-black-semi;
      // text-decoration: none;
    }
  }

  &__size {
    margin-left: 10px;
    // color: $elo-grey;
    font-size: var(--elo-font-size-sm);
    min-width: 75px;
    white-space: nowrap;

    &--right {
      margin-right: 10px;
      min-width: 52px;
      text-align: right;
    }
  }

  &__actions {
    width: 37%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &--disabled {
      i {
        cursor: default !important;

        &:hover {
          opacity: 1 !important;
        }
      }
    }

    &--padding-10 {
      padding: 0 10px;
    }

    &--not-deletable {
      margin-right: 0;

      .file-details__size--right {
        margin-right: 0;
      }
    }
  }

  &__icon {
    color: var(--dark-widget-icon-font-color);
    cursor: pointer;
    font-size: var(--elo-font-size-md);
    height: 22px;
    margin-left: 4px;

    &:hover {
      color: var(--dark-widget-icon-font-color-hover);
    }
  }

  &__state {
    line-height: 24px;
    white-space: nowrap;

    i {
      color: inherit;
      margin-right: 7px;
    }
  }
}

.upload-modal-files-list {
  hr {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .file-details {
    width: 100%;

    &__content {
      width: 100%;
      max-width: 100%;
    }

    &__content-container {
      width: 100%;
    }
  }
}

.upload-modal-files-list {
  .file-details {
    background-color: transparent;
  }

  .file-details__size,
  .file-details__name {
    color: var(--elo-dark-grey);
  }
}
