@import '../elo-inputs/elo-input';
.elo-select {
  --react-select-background-color: var(--color-primary-neutral-white);
  --elo-select-error-color: var(--color-utility-error-dark);

  z-index: unset;
  position: relative;

  &__list {
    z-index: 9;
  }

  &.elo-input {
    margin-bottom: 0;
  }

  // fix conflict with cabinet styles
  &.elo-input {
    margin-bottom: 0;
  }

  span.elo-checkbox__box {
    color: var(--react-select-background-color) !important;
  }

  &.elo-input--error {
    .elo-react-select__control {
      border-color: var(--elo-select-error-color);
    }
  }

  &.disabled {
    img,
    svg {
      filter: grayscale(1);
    }
  }

  &--searchable {
    .react-select {
      .react-select__single-value {
        top: 54%;
      }

      .react-select__control {
        max-height: 48px;

        .react-select__value-container {
          max-height: 48px;
          overflow: hidden;
        }
      }
    }
  }

  &.elo-select--large {
    .elo-react-select {
      .elo-react-select__control {
        min-height: 40px;

        .elo-react-select__input {
          input {
            height: 26px;
          }
        }

        .elo-react-select__indicators {
          .elo-react-select__dropdown-indicator {
            padding-top: 10px;
            width: 38px;
          }
        }

        .elo-react-select__value-container {
          min-height: 32px;

          .elo-react-select__multi-value {
            height: 24px;
            padding: 0 8px;
            padding-right: 30px;
            overflow-y: visible;

            &.select-multi-value-subtitle {
              height: initial;
              margin: 1px 4px 1px;

              .elo-react-select__multi-value__remove {
                top: 7px;
                right: 6px;
              }
            }

            .elo-react-select__multi-value__remove {
              top: 3px;
              right: 7px;
            }
          }
        }
      }

      .elo-react-select__option--avatar {
        width: 16px;
        height: 16px;
      }
    }

    .elo-react-select__menu {
      .elo-react-select__menu-list {
        .option-placeholder {
          height: 40px;
        }

        .elo-react-select__option {
          height: 40px;

          .elo-react-select__option--avatar {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
  .elo-select-warning {
    padding-top: 7px;
    cursor: pointer;
  }
}

.elo-select-actions {
  display: flex;
  flex-direction: row;

  .elo-select-action {
    display: block;
    width: 32px;
    padding: 0 10px;
    cursor: pointer;
  }
}

.elo-select-menu-list {
  &__load-more {
    display: flex;
    justify-content: center;
    padding: 8px 0;
    color: var(--color-primary-dg-300);
    font: var(--elo-body-sm-regular);

    &:hover {
      color: var(--react-select-color);
      background-color: var(--react-select-multi-value-background-color);
      cursor: pointer;
    }
  }
}

.elo-react-select,
.elo-react-select__menu-portal {
  --react-select-font: var(--elo-body-sm-regular);
  --react-select-color: var(--color-primary-dg-brand);
  --react-select-placeholder-color: var(--color-primary-dg-300);
  --react-select-border-color: var(--color-primary-dg-200);
  --react-select-background-color: var(--color-primary-neutral-white);
  --react-select-multi-value-background-color: var(--color-primary-cg-brand);
  --react-select-option-background-color: var(--color-primary-neutral-white);
  --elo-tag-icon-color: var(--color-primary-dg-300);
  --elo-select-error-color: var(--color-utility-error-dark);
  --react-select-subtitle-color: var(--color-primary-dg-400);
  --react-select-disabled-color: var(--color-primary-dg-100);

  .elo-react-select__single-value {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .elo-react-select__control {
    min-height: 48px;
    border-color: var(--react-select-border-color);
    box-shadow: none;
    background-color: var(--react-select-background-color);

    &:not(&.elo-react-select__control--is-disabled) {
      cursor: pointer;
    }

    &.elo-react-select__control--is-focused,
    &.elo-react-select__control--menu-is-open {
      border-color: var(--color-utility-success-dark);
    }

    &.elo-react-select__control--is-disabled {
      background-color: var(--react-select-disabled-color);
    }
    .elo-react-select__input {
      input {
        height: 28px;
      }
    }

    input[class$='dummyInput'],
    input[class$='DummyInput'] {
      width: 0;
      height: 0;
      opacity: 0;
    }

    .elo-react-select__value-container {
      padding-left: 12px;
      min-height: 32px;

      .elo-react-select__multi-value {
        height: 32px;
        background-color: var(--react-select-multi-value-background-color);
        padding: 4px 30px 4px 12px;
        border-radius: 8px;
        margin: 4px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .elo-react-select__multi-value__label {
          padding: 0;
          font-weight: 400;
          font-size: 14px;
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .elo-react-select__multi-value__remove {
          font-size: 10px;
          padding: 3px;
          margin-right: -3px;
          border-radius: 3px;
          position: absolute;
          top: 7px;
          right: 8px;

          &:hover {
            cursor: pointer;
            background: none;
            color: var(--elo-tag-icon-color);
          }

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .elo-react-select__indicators {
      .elo-react-select__dropdown-indicator {
        color: var(--react-select-color);
        padding-top: 8px;
        width: 42px;
      }
    }
  }

  .elo-react-select__menu {
    border: none;
    box-shadow: var(--shadow-elevation-3);
    margin-top: 0;
    z-index: 1000;
    background-color: var(--react-select-option-background-color);

    &-notice--loading,
    &-notice--no-options {
      font: var(--react-select-font);
    }

    .elo-react-select__menu-list {
      .option-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--react-select-placeholder-color);
        height: 48px;
        font: var(--react-select-font);
      }

      .elo-react-select__option {
        height: 48px;
        padding: 0 16px;
        color: var(--react-select-color);
        background-color: var(--react-select-option-background-color);
        font: var(--react-select-font);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        input[type='checkbox'] {
          display: none;
        }

        &.elo-react-select__option--is-focused {
          color: var(--react-select-color);
          background-color: var(--react-select-multi-value-background-color);
        }

        &:hover {
          color: var(--react-select-color);
          background-color: var(--react-select-multi-value-background-color);
          cursor: pointer;
        }

        &.elo-react-select__option--is-selected {
          background-color: var(--react-select-option-background-color);
          color: var(--color-highlight-click);

          &:hover {
            background-color: var(--react-select-multi-value-background-color);
            cursor: pointer;

            span {
              color: var(--react-select-color);
            }

            svg {
              color: var(--color-highlight-click);
            }
          }

          span {
            color: var(--react-select-color);
          }

          svg {
            color: var(--color-highlight-click);
          }
        }

        &.elo-react-select__option--is-disabled {
          color: var(--react-select-color);
          background-color: var(--react-select-border-color);
          cursor: not-allowed;
        }

        .elo-react-select__option--label {
          display: flex;
          flex-direction: column;
        }

        .select-item {
          flex-grow: 1;
          align-items: center;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  .elo-react-select__option--title {
    font-weight: 400;
    font-size: 14px;
    font: var(--react-select-font);
    color: var(--react-select-color);
  }

  .elo-react-select__option--subtitle {
    font-weight: 400;
    font-size: 12px;
    color: var(--react-select-subtitle-color);
  }

  .elo-react-select__option--wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .elo-react-select__option--avatar {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: var(--color-utility-alert-light);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
}
