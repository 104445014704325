@import '../../styles/colors';
@import '../../styles/fonts';

.grey-box {
  padding: 7px 16px;
  background-color: var(--box-bg-color);
  color: var(--box-font-color);
  border-radius: 20px;
  font-style: normal;
  white-space: nowrap;

  &--compact {
    min-width: 90px;
    width: 90px;
    height: 34px;
    padding: 12px 17px 11px 18px;
    font-size: 12px;
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    border-radius: 20.5px;
  }

  &--outlined-white {
    background-color: $elo-white;
    font-style: normal;
    border: 1px solid $elo-grey-40;
    color: $elo-grey-40;
    font-size: var(--elo-font-size-xs);
    max-width: 107px;
    padding: 11.5px 8px;
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    border-radius: 10px;
  }
}
