.audio-button {
  &::before {
    content: '\f04b';
  }

  &.play {
    &::before {
      content: '\f04c';
    }
  }
}
