@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/elo-field';

.elo-select-container {
  .field__error {
    margin-bottom: 0;
  }

  .elo-select-field {
    width: 100%;

    &__hide-option {
      .elo-select-field__option {
        padding: 0;
      }
    }

    &__control {
      box-sizing: border-box;
      line-height: 1.4;
      border: 1px solid var(--field-border-color);
      border-radius: 4px;
      background-color: var(--field-bg-color);
      box-shadow: none;

      &:hover {
        border-color: var(--field-border-color);
      }

      &--is-focused:not(&--error) {
        border-color: var(--field-border-color-focus);
      }

      &--is-focused:hover,
      &--is-focused:not(:hover) {
        border-color: var(--field-border-color-focus);
      }
    }

    &__value-container {
      padding: 12px 18px;
      line-height: 1.4;
    }

    &__placeholder {
      color: var(--field-placeholder-font-color) !important;
      font-size: var(--elo-font-size-sm) !important;
      margin: 0;
    }

    &__menu {
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: 0 3px 15px 0 rgba($elo-black, 0.2);
      z-index: 10000;
    }

    &__menu-list {
      max-height: 200px;
      padding-top: 0;
      padding-bottom: 0;
    }

    &__multi-value {
      border-radius: 4px;
      border: solid 1px var(--tag-border-color);
      background-color: var(--tag-bg-color);
      padding: 5px 6px;

      &__label {
        font-size: var(--elo-font-size-sm);
        font-family: var(--elo-font-sans-serif);
        font-weight: 400;
        color: var(--tag-font-color);
        border-radius: 1px;
      }

      &__remove {
        color: var(--tag-button-color);
        border-radius: 1px;

        &:hover {
          cursor: pointer;
          color: var(--tag-button-color-hover);
          background-color: var(--tag-bg-color);
        }
      }
    }

    &__dropdown-indicator {
      padding-top: 4px;
      padding-right: 21px;
      font-size: var(--elo-font-size-md);
      color: var(--select-indicator-font-color);

      &--arrow-up-position {
        padding-top: 8px;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__clear-indicator {
      svg {
        height: 16px;
        margin-top: 2px;
        color: $elo-grey;

        &:hover {
          color: $elo-black-semi;
        }
      }
    }

    &__option {
      padding: 8px 12px;

      &--is-focused {
        background-color: var(--select-item-bg-color-hover);
      }

      &:active {
        background-color: var(--select-item-bg-color-hover);
      }

      &--is-selected {
        background-color: var(--select-item-bg-color-selected);

        .cashout-option-label__provider {
          color: $elo-white;
        }
      }
    }

    &.searchable {
      .elo-select-field__value-container {
        padding: 9px 10px;
        line-height: 1.1;
      }
    }

    &.error {
      .elo-select-field__control {
        border-color: var(--field-border-color-error);
      }
    }

    &__creatable_filter {
      .elo-select-field {
        &__menu-notice {
          &--no-options {
            padding: 0;
          }
        }

        &__dropdown-indicator {
          display: none;
        }
      }
    }
  }

  .select-display-top {
    .elo-select-field__menu {
      margin-bottom: 0;
      border-radius: 4px 4px 0 0;
    }
  }
}
