@import 'src/ui-kit/styles/colors';

.cover-widget {
  &__type-icon {
    line-height: 0;
    background-color: var(--cover-widget-bg-color);
    border-radius: 50%;
    position: absolute;
    left: 16px;
    bottom: -11px;
    z-index: 1;

    i {
      font-size: 14px;
      color: var(--cover-widget-icon-font-color);
    }

    &--digital {
      padding: 8px 6.3px 8px 7.3px;
    }

    &--certificate {
      padding: 8px 7.7px 8px 8.5px;
    }

    &--license_key {
      padding: 8px;
    }

    &--download {
      padding: 8px 7.5px 8px 8.5px;
    }

    &--course {
      padding: 8px 6px 8px 7.5px;
    }

    &--ticket {
      padding: 8px 7.5px 8px 7.6px;
    }

    &--upsell {
      padding: 7px 7.3px 7.7px 7.5px;
    }

    &--membership {
      padding: 8px 7.3px 8.8px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  &__item {
    width: 400px;
    height: 265px;
  }

  &__image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 270px;
    height: 270px;
    position: relative;
    border-radius: 6px 6px 0 0;
    flex-shrink: 0;
  }

  &__clickable {
    cursor: pointer;
  }

  &__no-image-icon {
    font-size: 45px;
    color: var(--image-cover-font-color);
  }

  &__no-image {
    width: 270px;
    height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0 0;
    background-color: var(--image-cover-bg-color);
    position: relative;
    flex-shrink: 0;
  }
}

@media (max-width: 400px) {
  .cover-widget__image:not(.cover-widget__image--unresponsive),
  .cover-widget__no-image:not(.cover-widget__no-image--unresponsive) {
    width: 230px;
    height: 230px;
  }
}

@media (max-width: 330px) {
  .cover-widget__image:not(.cover-widget__image--unresponsive),
  .cover-widget__no-image:not(.cover-widget__no-image--unresponsive) {
    width: 190px;
    height: 190px;
  }
}
