@import 'src/ui-kit/styles/page-builder.scss';
@import './top-panel';
@import './side-panel/side-panel';

.page-builder {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $builder-grey-10;
  z-index: 15;
  display: flex;

  &--hidden {
    display: none;
  }

  &__page-view {
    height: 100%;
    overflow: scroll;
    background-color: $elo-grey-20;
  }

  &__view-panel {
    width: calc(100% - #{$builder-side-panel-width});
    background-color: $elo-white;
  }

  &__highlight-menu-close {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
  }
}

.add-products-widget {
  &__item-container {
    z-index: 1050;
  }
}
