@import 'src/ui-kit/styles/colors';

.social-proof-bubble {
  border: 1px solid;
  padding: 6px;
  width: 300px;
  height: 75px;

  &__rounded {
    border-radius: 50px;
  }

  &__square {
    border-radius: 4px;
  }

  &--content {
    display: flex;
    align-items: center;
  }

  &--badge {
    border-radius: 30px;
    margin-right: 12px;
    font-size: 24px;
    line-height: 30px;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__big {
      font-size: 30px;
    }
  }

  &--text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 55px;

    &__header {
      font-weight: bold;
      font-size: var(--elo-font-size-xs);
    }

    &__description {
      font-size: 10px;
    }
  }

  &__logo {
    position: absolute;
    right: 25px;
    bottom: 5px;
  }

  &__close {
    position: absolute;
    top: 2px;
    right: 2px;
    display: none;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    background: var(--elo-dark-grey-40);
    color: var(--elo-white);
    border-radius: 15px;
    font-size: var(--elo-font-size-lg);
    cursor: pointer;
  }

  &:hover &__close {
    display: flex;
  }
}

.checkout-page-bubble {
  position: fixed;
  bottom: 16px;
  left: 16px;
  z-index: 20;

  @media (max-width: 991px) {
    left: 50%;
    margin-left: -150px;
  }
}

.bubble-creation {
  position: relative;
  margin-bottom: 15px;

  .social-proof-bubble__close {
    display: flex;
    cursor: unset;
  }
}

.social-proof-bubble__default {
  border-color: var(--elo-light-grey-50);
  background-color: var(--elo-white);

  .social-proof-bubble--badge {
    background-color: var(--elo-grey-80);
    color: var(--elo-white);
  }

  .social-proof-bubble--text__header {
    color: var(--elo-grey-80);
  }

  .social-proof-bubble--text__description {
    color: var(--elo-grey-80);
  }
}

.social-proof-bubble__mono {
  border-color: var(--elo-light-grey-50);
  background-color: var(--elo-white);

  .social-proof-bubble--badge {
    background-color: var(--elo-dark-grey);
    color: var(--elo-white);
  }

  .social-proof-bubble--text__header {
    color: var(--elo-dark-grey);
  }

  .social-proof-bubble--text__description {
    color: var(--elo-grey-80);
  }
}

.social-proof-bubble__elopage_light {
  border-color: var(--elo-dark-grey);
  background-color: var(--elo-white);

  .social-proof-bubble--badge {
    background-color: var(--elo-green);
    color: var(--elo-dark-grey);
  }

  .social-proof-bubble--text__header {
    color: var(--elo-dark-grey);
  }

  .social-proof-bubble--text__description {
    color: var(--elo-grey-80);
  }
}

.social-proof-bubble__elopage_dark {
  border-color: var(--elo-dark-grey);
  background-color: var(--elo-dark-grey);

  .social-proof-bubble--badge {
    background-color: var(--elo-green);
    color: var(--elo-dark-grey);
  }

  .social-proof-bubble--text__header {
    color: var(--elo-white);
  }

  .social-proof-bubble--text__description {
    color: var(--elo-white);
  }
}

.social-proof-bubble__reverse_mono {
  border-color: var(--elo-dark-grey);
  background-color: var(--elo-dark-grey);

  .social-proof-bubble--badge {
    background-color: var(--elo-white);
    color: var(--elo-dark-grey);
  }

  .social-proof-bubble--text__header {
    color: var(--elo-white);
  }

  .social-proof-bubble--text__description {
    color: var(--elo-white);
  }
}

.social-proof-bubble__grey {
  border-color: var(--elo-grey-80);
  background-color: var(--elo-grey-80);

  .social-proof-bubble--badge {
    background-color: var(--elo-white);
    color: var(--elo-grey-80);
  }

  .social-proof-bubble--text__header {
    color: var(--elo-white);
  }

  .social-proof-bubble--text__description {
    color: var(--elo-white);
  }
}
