@import '../../../styles/colors';
@import '../../../styles/fonts';

$theme-orange: #fff8eb;
$light-orange: #fffaee;

.warning-info {
  background-color: var(--warning-info-bg-color);
  border: solid 1px var(--warning-info-border-color);
  border-radius: 4px;
  color: var(--info-font-color);
  font-size: 13px;
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: var(--elo-font-size-xs);
  width: 100%;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  & + .file-list-wrap {
    margin-top: 10px;
  }

  i {
    font-size: var(--elo-font-size-md);
    margin-right: var(--elo-font-size-xs);

    &.fa-exclamation-triangle {
      color: $elo-orange;
    }

    &.fa-lightbulb {
      color: $elo-orange;
    }

    &.fa-ban {
      color: $elo-red;
    }
  }

  a {
    color: var(--link-font-color);

    &:hover {
      text-decoration: none;
      color: var(--link-font-color-hover);
    }
  }

  &__link {
    color: var(--link-font-color);
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: var(--link-font-color-hover);
    }
  }

  &__modal {
    margin: 0 15px 30px;
  }

  &__title {
    font-size: var(--elo-font-size-md);
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    color: var(--elo-dark-grey);
    font-style: normal;
  }

  &__description {
    margin-left: -30px;
    margin-top: 5px;
  }

  &--border {
    &-orange {
      border-color: $elo-orange;
    }
  }

  &--theme-orange {
    border-color: $elo-orange;
    background-color: $theme-orange;
    color: $elo-orange;
    font-style: normal;
  }

  &--light-orange {
    background-color: $light-orange;
    padding: 25px;
    border: 2px solid $elo-orange;
  }

  &--theme-grey {
    background-color: var(--elo-light-grey-10);
    border: 1px solid var(--elo-light-grey-30);
  }

  .close-btn {
    margin-left: auto;
    color: $elo-grey;

    &:hover {
      color: $elo-grey-dark;
      cursor: pointer;
    }
  }

  &--with-title {
    padding: 23px 27px 18px 24px;
    font-style: normal;
  }

  &--no-margin-top {
    margin-top: 0;
  }

  &--no-margin {
    margin: 0;
  }

  &--no-margin-bottom {
    margin-bottom: 0;
  }

  &__container {
    display: flex;
    width: 100%;
  }

  &__text {
    margin-right: auto;

    &--left {
      text-align: left;
    }
  }
}
