@import '../../../../../styles/page-builder';

.elo-search-field {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 14px;
  width: 100%;
  max-width: 100%;

  &__control {
    max-width: 100%;
    height: 30px;
    font-family: $builder-font-medium;
    background-color: var(--field-bg-color);
    border-radius: 2px;
    border: 1px solid var(--field-border-color);
    padding-left: 10px;
    padding-right: 10px;
    line-height: 1;
    display: flex;
    align-items: center;

    &::placeholder {
      padding-top: 5px;
    }
  }

  // Override Bootstrap styles
  .elo-search-field__control::placeholder {
    color: var(--field-placeholder-font-color) !important;
    font-size: 14px !important;
    line-height: 1;

    //* Safari specific selector */
    @media screen and (min-color-index: 0) and (min-resolution: 0dpi) {
      padding-top: 0;
    }
  }

  & + .builder-scaled-content-block__container {
    padding-top: 0;
  }

  &__input-wrapper {
    position: relative;
    max-width: 100%;
    width: 100%;
  }

  &__icon {
    position: absolute;
    top: 9px;
    right: 10px;
    font-size: 14px;
    color: $elo-white;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
