.side-panel__tabs {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid $builder-grey-30;
  border-bottom: 1px solid $builder-grey-30;
  min-height: 48px;

  &__tab {
    display: flex;
    flex-wrap: wrap;
    width: 92px;
    padding-left: 0;
    list-style: none;
    margin-bottom: -1px;
    border-right: 1px solid $builder-grey-10;
    background-color: $builder-grey-20;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $elo-grey-70;
    text-align: center;

    &:last-child {
      border-right: none;
    }

    &:hover {
      background-color: $elo-grey-25;
    }

    &:active {
      background-color: $elo-grey-45;
    }

    &--active {
      text-decoration: none;
      background-color: $builder-grey-10;
      color: $elo-grey-90;
      &:hover,
      &:active {
        background-color: $builder-grey-10;
      }
    }
  }

  &__tab-label {
    font-size: 12px;
    font-family: $builder-font-bold;
    line-height: 1;
    display: block;
    text-transform: capitalize;
  }
}
