@import 'src/ui-kit/styles/colors';
@import '../../styles/page-builder';

.edit-text-area {
  position: relative;
  min-width: 20px;

  &:hover {
    outline: 1px dashed $elo-grey-dark;
    outline-offset: -0.6px;

    .edit-text-area__hint {
      display: flex;
    }
  }

  &--with-padding {
    padding: 50px;
  }

  &__hint {
    display: none;
    position: absolute;
    font-size: var(--elo-font-size-xs);
    font-family: $builder-font-medium;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    width: 136px;
    height: 25px;
    border-bottom-left-radius: 4px;
    color: $elo-white;
    background-color: $elo-black;
    line-height: 1;
    z-index: 1;

    i {
      font-size: var(--elo-font-size-sm) !important;
    }
  }
}
